import { Action, State, StateContext, Store } from "@ngxs/store";
import { MEMBER_FEATURE_KEY, MemberStateModel } from "./member.state.model";
import { Injectable } from "@angular/core";
import { MemberService } from "@app/core/api/index";
import { LoadMemberAction } from "./member.actions";
import { tap, catchError, EMPTY } from "rxjs";
import { NotificationsService } from "@app/core/services/notifications";
import { HttpErrorResponse } from "@angular/common/http";

@State<MemberStateModel>({
    name: MEMBER_FEATURE_KEY,
    defaults: {
        member: {
            entity: null,
            isLoading: false,
            error: null,
        },
    },
})
@Injectable({ providedIn: "root" })
export class MemberState {
    constructor(private memberService: MemberService, private store: Store, private notificationsService: NotificationsService) {}

    @Action(LoadMemberAction)
    getMember(ctx: StateContext<MemberStateModel>, action: LoadMemberAction) {
        ctx.patchState({
            member: {
                isLoading: true,
                error: null,
                entity: null,
            },
        });

        return this.memberService.memberMemberIdGet({ memberId: action.memberId }).pipe(
            tap((member) => {
                ctx.patchState({ member: { entity: member, error: null, isLoading: false } });
            }),
            catchError((error: HttpErrorResponse) => {
                this.notificationsService.error("An unexpected error occurred while fetching member.");
                ctx.patchState({
                    member: {
                        entity: null,
                        isLoading: false,
                        error: error.statusText,
                    },
                });
                return EMPTY;
            }),
        );
    }
}
