export const environment = {
    production: true,
    baseUrl: "https://member-management-api-tst.eosfitness.io",
    msal_clientId: "a2643321-a673-4d8f-bcd0-e42a64cd46d6", //application Id
    msal_tenantId: "37f0ee90-c397-44fe-b310-e3b58990e8f6",
    msal_authority: "https://login.microsoftonline.com/37f0ee90-c397-44fe-b310-e3b58990e8f6", //tenant Id [Qodeca, Eos fitness, ...]
    msal_redirectUri: "https://member-management-tst.eosfitness.io",
    apiAccessUri: "api://eos-member-management-api-tst/api",
    websiteBaseUrl: "https://website-tst.eosfitness.io/",
};
